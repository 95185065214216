footer.footer {
  position: relative;
  background: #2b2b2b;
  color: #d3d3d3;
  min-height: 100px;
}

footer.footer h2 {
  color: #fff;
}

footer.footer a {
  color: #d3d3d3;
}

footer.footer .mdl-grid {
  padding: 18px;
}

footer.footer .mdl-cell {
  border-top: 1px solid #696969;
}

.footer-wrapper {
  margin: 10px 0;
}

.footer-wrapper__content {
  max-width: 812px;
  margin: 0 auto;
}

.footer-wrapper__content ul {
  padding: 0;
}

.footer-wrapper__content li {
  list-style: none;
}

.footer-bar {
  width: 100%;
  background: #424242;
  color: #fff;
}

.footer-bar__content {
  height: 48px;
  line-height: 48px;
  max-width: 812px;
  padding: 0 4px;
  margin: 0 auto;
}

@media (min-width: 424px) {
  .footer {
    min-height: 150px;
  }
}

@media (min-width: 600px) {
  .footer {
    min-height: 200px;
  }
}
