section.page-content {
  margin: 0 auto;
  max-width: 864px;
  padding: 24px 8px;
}

section.page-content .page-content__title {
  font-size: 30px;
}

section.page-content img {
  max-width: 100%;
}

.scroll-container {
  overflow-x: scroll;
}

.calendar-row--expired {
  color: rgba(68, 68, 82, 0.4);
}

.page-content__years-container {
  text-align: center;
  margin: 30px 0;
  padding: 0;
}

.page-content__year {
  cursor: pointer;
  display: inline;
  border: 1px solid green;
  border-radius: 4px;
  padding: 10px;
  margin: 0 10px;
  color: green;
}

.page-content__year--active {
  font-weight: 700;
}
