header.header {
  position: relative;
  height: 64px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  margin-bottom: -24px;
}

header.header:before {
  width: 100%;
  position: absolute;
  height: 48px;
  content: '';
  background: #fff;
  top: 0;
  left: 0;
}

.header__content {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 864px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 12px;
}

.logo {
  -ms-flex-align: center;
  position: relative;
  padding: 8px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.logo,
.logo__icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.logo__icon {
  width: 100%;
  padding-left: 48px;
  height: 32px;
  overflow: hidden;
  margin-right: 16px;
  -ms-flex-align: center;
  text-decoration: none;
}

.logo__icon img {
  display: none;
}

.logo__icon:after {
  content: 'Home';
  padding-left: 14px;
  color: #404040;
}

.menu__nav {
  list-style: none;
  padding: 40px 0 0;
  margin: 0;
  display: none;
  height: 100%;
  background: #fff;
  width: 90%;
  top: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}

.menu__nav-item-container {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu__nav a {
  font-size: 16px;
  color: #404040;
  width: 100%;
  display: block;
  padding: 8px;
  text-decoration: none;
}

.menu__nav a:focus,
.menu__nav a:hover {
  color: #4caf50;
  background: #f0f0f0;
}

.menu__nav-item--active a {
  color: #4caf50;
  font-weight: 700;
}

.animatable .menu--enhanced {
  overflow: hidden;
}

.animatable .menu--enhanced .menu__nav {
  display: block;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.3, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.3, 1);
  transition: transform 0.3s cubic-bezier(0, 0, 0.3, 1);
  transition: transform 0.3s cubic-bezier(0, 0, 0.3, 1),
    -webkit-transform 0.3s cubic-bezier(0, 0, 0.3, 1);
}

.animatable .menu--enhanced .menu__toggle:checked ~ .menu__nav {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.menu__underlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.menu__toggle {
  opacity: 0;
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 3;
}

.menu__toggle-label {
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 12px;
  right: 8px;
  z-index: 2;
}

@media (max-width: 423px) {
  .menu--enhanced .menu__nav,
  .menu__toggle:checked ~ .menu__nav {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 80%;
  }
}

@media (min-width: 424px) {
  header.header {
    height: 88px;
  }

  header.header:before {
    height: 64px;
  }

  .menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .animatable .menu--enhanced .menu__nav {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  .menu__toggle,
  .menu__toggle-label,
  .menu__underlay {
    display: none;
  }

  .menu__nav {
    display: block;
    position: relative;
    height: auto;
    width: auto;
    padding: 0;
  }

  .menu__nav-item {
    display: inline;
    margin-right: 16px;
  }

  .menu__nav-item:last-of-type {
    margin-right: 8px;
  }

  .menu__nav-item a {
    font-size: 14px;
    padding: 0;
    display: inline;
  }

  .menu__nav-item a:focus,
  .menu__nav-item a:hover {
    background: none;
  }

  .logo__icon {
    padding-left: 0;
    height: 48px;
  }

  .logo__icon img {
    height: 48px;
    display: block;
  }
}

@media (min-width: 600px) {
  headers.header {
    height: 96px;
  }

  .header:before {
    height: 72px;
  }

  .menu {
    height: 80px;
  }

  .menu__nav-item a {
    font-size: 16px;
  }

  .menu__nav-item:last-of-type {
    margin-right: 16px;
  }
}
