* {
  box-sizing: border-box;
}

body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  color: #444;
}

body {
  background: #fafafa;
  line-height: 1.5;
}

h2 {
  padding-top: 8px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #2b2b2b;
}

a {
  color: #4caf50;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  text-align: left;
}

.table td,
.table th,
.table tr {
  padding: 8px;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.table--hover tr:hover {
  background-color: #f5f5f5;
}

@media (max-width: 423px) {
  .hide-mobile {
    display: none;
  }
}

.hidden {
  display: none;
}

.clickable {
  cursor: pointer;
}

.mdl-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0 auto;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.mdl-grid.mdl-grid--no-spacing {
  padding: 0;
}

.mdl-cell {
  box-sizing: border-box;
}

.mdl-cell--top {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.mdl-cell--middle {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.mdl-cell--bottom {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.mdl-cell--stretch {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.mdl-grid.mdl-grid--no-spacing > .mdl-cell {
  margin: 0;
}

.mdl-cell--order-1 {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.mdl-cell--order-2 {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}

.mdl-cell--order-3 {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
}

.mdl-cell--order-4 {
  -webkit-box-ordinal-group: 5;
  -webkit-order: 4;
  -ms-flex-order: 4;
  order: 4;
}

.mdl-cell--order-5 {
  -webkit-box-ordinal-group: 6;
  -webkit-order: 5;
  -ms-flex-order: 5;
  order: 5;
}

.mdl-cell--order-6 {
  -webkit-box-ordinal-group: 7;
  -webkit-order: 6;
  -ms-flex-order: 6;
  order: 6;
}

.mdl-cell--order-7 {
  -webkit-box-ordinal-group: 8;
  -webkit-order: 7;
  -ms-flex-order: 7;
  order: 7;
}

.mdl-cell--order-8 {
  -webkit-box-ordinal-group: 9;
  -webkit-order: 8;
  -ms-flex-order: 8;
  order: 8;
}

.mdl-cell--order-9 {
  -webkit-box-ordinal-group: 10;
  -webkit-order: 9;
  -ms-flex-order: 9;
  order: 9;
}

.mdl-cell--order-10 {
  -webkit-box-ordinal-group: 11;
  -webkit-order: 10;
  -ms-flex-order: 10;
  order: 10;
}

.mdl-cell--order-11 {
  -webkit-box-ordinal-group: 12;
  -webkit-order: 11;
  -ms-flex-order: 11;
  order: 11;
}

.mdl-cell--order-12 {
  -webkit-box-ordinal-group: 13;
  -webkit-order: 12;
  -ms-flex-order: 12;
  order: 12;
}

@media (max-width: 479px) {
  .mdl-grid {
    padding: 8px;
  }

  .mdl-cell {
    margin: 8px;
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell {
    width: 100%;
  }

  .mdl-cell--hide-phone {
    display: none !important;
  }

  .mdl-cell--order-1-phone.mdl-cell--order-1-phone {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .mdl-cell--order-2-phone.mdl-cell--order-2-phone {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .mdl-cell--order-3-phone.mdl-cell--order-3-phone {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .mdl-cell--order-4-phone.mdl-cell--order-4-phone {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }

  .mdl-cell--order-5-phone.mdl-cell--order-5-phone {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }

  .mdl-cell--order-6-phone.mdl-cell--order-6-phone {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }

  .mdl-cell--order-7-phone.mdl-cell--order-7-phone {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }

  .mdl-cell--order-8-phone.mdl-cell--order-8-phone {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }

  .mdl-cell--order-9-phone.mdl-cell--order-9-phone {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }

  .mdl-cell--order-10-phone.mdl-cell--order-10-phone {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }

  .mdl-cell--order-11-phone.mdl-cell--order-11-phone {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }

  .mdl-cell--order-12-phone.mdl-cell--order-12-phone {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }

  .mdl-cell--1-col,
  .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
    width: calc(25% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--1-col,
  .mdl-grid--no-spacing > .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
    width: 25%;
  }

  .mdl-cell--2-col,
  .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
    width: calc(50% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--2-col,
  .mdl-grid--no-spacing > .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
    width: 50%;
  }

  .mdl-cell--3-col,
  .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    width: calc(75% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--3-col,
  .mdl-grid--no-spacing > .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    width: 75%;
  }

  .mdl-cell--4-col,
  .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--4-col,
  .mdl-grid--no-spacing > .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
    width: 100%;
  }

  .mdl-cell--5-col,
  .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--5-col,
  .mdl-grid--no-spacing > .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
    width: 100%;
  }

  .mdl-cell--6-col,
  .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--6-col,
  .mdl-grid--no-spacing > .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
    width: 100%;
  }

  .mdl-cell--7-col,
  .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--7-col,
  .mdl-grid--no-spacing > .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
    width: 100%;
  }

  .mdl-cell--8-col,
  .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--8-col,
  .mdl-grid--no-spacing > .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
    width: 100%;
  }

  .mdl-cell--9-col,
  .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--9-col,
  .mdl-grid--no-spacing > .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
    width: 100%;
  }

  .mdl-cell--10-col,
  .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--10-col,
  .mdl-grid--no-spacing > .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
    width: 100%;
  }

  .mdl-cell--11-col,
  .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--11-col,
  .mdl-grid--no-spacing > .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
    width: 100%;
  }

  .mdl-cell--12-col,
  .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--12-col,
  .mdl-grid--no-spacing > .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
    width: 100%;
  }

  .mdl-cell--1-offset,
  .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
    margin-left: calc(25% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
    margin-left: 25%;
  }

  .mdl-cell--2-offset,
  .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
    margin-left: calc(50% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
    margin-left: 50%;
  }

  .mdl-cell--3-offset,
  .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
    margin-left: calc(75% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
    margin-left: 75%;
  }
}

@media (min-width: 480px) and (max-width: 839px) {
  .mdl-grid {
    padding: 8px;
  }

  .mdl-cell {
    margin: 8px;
    width: calc(50% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell {
    width: 50%;
  }

  .mdl-cell--hide-tablet {
    display: none !important;
  }

  .mdl-cell--order-1-tablet.mdl-cell--order-1-tablet {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .mdl-cell--order-2-tablet.mdl-cell--order-2-tablet {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .mdl-cell--order-3-tablet.mdl-cell--order-3-tablet {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .mdl-cell--order-4-tablet.mdl-cell--order-4-tablet {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }

  .mdl-cell--order-5-tablet.mdl-cell--order-5-tablet {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }

  .mdl-cell--order-6-tablet.mdl-cell--order-6-tablet {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }

  .mdl-cell--order-7-tablet.mdl-cell--order-7-tablet {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }

  .mdl-cell--order-8-tablet.mdl-cell--order-8-tablet {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }

  .mdl-cell--order-9-tablet.mdl-cell--order-9-tablet {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }

  .mdl-cell--order-10-tablet.mdl-cell--order-10-tablet {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }

  .mdl-cell--order-11-tablet.mdl-cell--order-11-tablet {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }

  .mdl-cell--order-12-tablet.mdl-cell--order-12-tablet {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }

  .mdl-cell--1-col,
  .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
    width: calc(12.5% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--1-col,
  .mdl-grid--no-spacing > .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
    width: 12.5%;
  }

  .mdl-cell--2-col,
  .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
    width: calc(25% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--2-col,
  .mdl-grid--no-spacing > .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
    width: 25%;
  }

  .mdl-cell--3-col,
  .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    width: calc(37.5% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--3-col,
  .mdl-grid--no-spacing > .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    width: 37.5%;
  }

  .mdl-cell--4-col,
  .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
    width: calc(50% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--4-col,
  .mdl-grid--no-spacing > .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
    width: 50%;
  }

  .mdl-cell--5-col,
  .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
    width: calc(62.5% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--5-col,
  .mdl-grid--no-spacing > .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
    width: 62.5%;
  }

  .mdl-cell--6-col,
  .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
    width: calc(75% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--6-col,
  .mdl-grid--no-spacing > .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
    width: 75%;
  }

  .mdl-cell--7-col,
  .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
    width: calc(87.5% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--7-col,
  .mdl-grid--no-spacing > .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
    width: 87.5%;
  }

  .mdl-cell--8-col,
  .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--8-col,
  .mdl-grid--no-spacing > .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
    width: 100%;
  }

  .mdl-cell--9-col,
  .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--9-col,
  .mdl-grid--no-spacing > .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
    width: 100%;
  }

  .mdl-cell--10-col,
  .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--10-col,
  .mdl-grid--no-spacing > .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
    width: 100%;
  }

  .mdl-cell--11-col,
  .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--11-col,
  .mdl-grid--no-spacing > .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
    width: 100%;
  }

  .mdl-cell--12-col,
  .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--12-col,
  .mdl-grid--no-spacing > .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
    width: 100%;
  }

  .mdl-cell--1-offset,
  .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
    margin-left: calc(12.5% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
    margin-left: 12.5%;
  }

  .mdl-cell--2-offset,
  .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
    margin-left: calc(25% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
    margin-left: 25%;
  }

  .mdl-cell--3-offset,
  .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
    margin-left: calc(37.5% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
    margin-left: 37.5%;
  }

  .mdl-cell--4-offset,
  .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
    margin-left: calc(50% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
    margin-left: 50%;
  }

  .mdl-cell--5-offset,
  .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
    margin-left: calc(62.5% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
    margin-left: 62.5%;
  }

  .mdl-cell--6-offset,
  .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
    margin-left: calc(75% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
    margin-left: 75%;
  }

  .mdl-cell--7-offset,
  .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
    margin-left: calc(87.5% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
    margin-left: 87.5%;
  }
}

@media (min-width: 840px) {
  .mdl-grid {
    padding: 8px;
  }

  .mdl-cell {
    margin: 8px;
    width: calc(33.33333% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell {
    width: 33.33333%;
  }

  .mdl-cell--hide-desktop {
    display: none !important;
  }

  .mdl-cell--order-1-desktop.mdl-cell--order-1-desktop {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .mdl-cell--order-2-desktop.mdl-cell--order-2-desktop {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .mdl-cell--order-3-desktop.mdl-cell--order-3-desktop {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .mdl-cell--order-4-desktop.mdl-cell--order-4-desktop {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }

  .mdl-cell--order-5-desktop.mdl-cell--order-5-desktop {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }

  .mdl-cell--order-6-desktop.mdl-cell--order-6-desktop {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }

  .mdl-cell--order-7-desktop.mdl-cell--order-7-desktop {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }

  .mdl-cell--order-8-desktop.mdl-cell--order-8-desktop {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }

  .mdl-cell--order-9-desktop.mdl-cell--order-9-desktop {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }

  .mdl-cell--order-10-desktop.mdl-cell--order-10-desktop {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }

  .mdl-cell--order-11-desktop.mdl-cell--order-11-desktop {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }

  .mdl-cell--order-12-desktop.mdl-cell--order-12-desktop {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }

  .mdl-cell--1-col,
  .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
    width: calc(8.33333% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--1-col,
  .mdl-grid--no-spacing > .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
    width: 8.33333%;
  }

  .mdl-cell--2-col,
  .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
    width: calc(16.66667% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--2-col,
  .mdl-grid--no-spacing > .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
    width: 16.66667%;
  }

  .mdl-cell--3-col,
  .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
    width: calc(25% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--3-col,
  .mdl-grid--no-spacing > .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
    width: 25%;
  }

  .mdl-cell--4-col,
  .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
    width: calc(33.33333% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--4-col,
  .mdl-grid--no-spacing > .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
    width: 33.33333%;
  }

  .mdl-cell--5-col,
  .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
    width: calc(41.66667% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--5-col,
  .mdl-grid--no-spacing > .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
    width: 41.66667%;
  }

  .mdl-cell--6-col,
  .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: calc(50% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--6-col,
  .mdl-grid--no-spacing > .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: 50%;
  }

  .mdl-cell--7-col,
  .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
    width: calc(58.33333% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--7-col,
  .mdl-grid--no-spacing > .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
    width: 58.33333%;
  }

  .mdl-cell--8-col,
  .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
    width: calc(66.66667% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--8-col,
  .mdl-grid--no-spacing > .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
    width: 66.66667%;
  }

  .mdl-cell--9-col,
  .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
    width: calc(75% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--9-col,
  .mdl-grid--no-spacing > .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
    width: 75%;
  }

  .mdl-cell--10-col,
  .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
    width: calc(83.33333% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--10-col,
  .mdl-grid--no-spacing > .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
    width: 83.33333%;
  }

  .mdl-cell--11-col,
  .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
    width: calc(91.66667% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--11-col,
  .mdl-grid--no-spacing > .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
    width: 91.66667%;
  }

  .mdl-cell--12-col,
  .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--12-col,
  .mdl-grid--no-spacing > .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
    width: 100%;
  }

  .mdl-cell--1-offset,
  .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
    margin-left: calc(8.33333% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
    margin-left: 8.33333%;
  }

  .mdl-cell--2-offset,
  .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
    margin-left: calc(16.66667% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
    margin-left: 16.66667%;
  }

  .mdl-cell--3-offset,
  .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
    margin-left: calc(25% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
    margin-left: 25%;
  }

  .mdl-cell--4-offset,
  .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
    margin-left: calc(33.33333% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
    margin-left: 33.33333%;
  }

  .mdl-cell--5-offset,
  .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
    margin-left: calc(41.66667% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
    margin-left: 41.66667%;
  }

  .mdl-cell--6-offset,
  .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
    margin-left: calc(50% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
    margin-left: 50%;
  }

  .mdl-cell--7-offset,
  .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
    margin-left: calc(58.33333% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
    margin-left: 58.33333%;
  }

  .mdl-cell--8-offset,
  .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
    margin-left: calc(66.66667% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--8-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
    margin-left: 66.66667%;
  }

  .mdl-cell--9-offset,
  .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
    margin-left: calc(75% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--9-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
    margin-left: 75%;
  }

  .mdl-cell--10-offset,
  .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
    margin-left: calc(83.33333% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--10-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
    margin-left: 83.33333%;
  }

  .mdl-cell--11-offset,
  .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
    margin-left: calc(91.66667% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--11-offset,
  .mdl-grid.mdl-grid--no-spacing
    > .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
    margin-left: 91.66667%;
  }
}
