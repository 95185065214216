.banner {
  background: #222;
  height: 200px;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: 50%;
}

.banner__title {
  position: absolute;
  bottom: 0;
  display: block;
  font-size: 28px;
  font-weight: 100;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #fff;
  max-width: 864px;
  left: 45%;
  -webkit-transform: translateX(-60%);
  -ms-transform: translateX(-60%);
  transform: translateX(-60%);
  background: rgba(0, 0, 0, 0.3);
  white-space: nowrap;
}

.banner--homepage {
  background-image: url('https://wtczachtjesaan.be/api/wp-content/uploads/2018/03/053-e1520709719716.jpg');
}

@media (min-width: 424px) {
  .banner__title {
    font-size: 32px;
    font-weight: 100;
  }
}

@media (min-width: 600px) {
  .banner {
    height: 300px;
  }

  .banner__title {
    font-size: 48px;
    font-weight: 100;
    left: 32%;
  }
}

@media (min-width: 1440px) {
  .banner {
    height: 400px;
  }

  .banner__title {
    font-size: 64px;
    font-weight: 100;
  }
}
