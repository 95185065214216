a.card {
  color: #2b2b2b;
}

.card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card--shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.card__cover {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: stretch;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  background-size: cover;
  background-position: 50%;
  height: 170px;
  padding: 20px;
}

.card__title {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-size: cover;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 700;
  padding: 16px;
  margin: 0;
}

.card__content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1% 0%;
  -ms-flex: 1 1% 0%;
  flex: 1 1% 0%;
  padding: 16px;
}

.card__action {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
}
